<template>
  <v-card elevation="0" max-width="500" class="ma-auto">
    <v-img :src="img" :lazy-src="require('@/assets/images/default-image.png')">
      <template v-if="lazy" v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="primary" />
        </v-row>
      </template>
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true
    },
    lazy: {
      type: Boolean,
      required: true
    }
  }
};
</script>
